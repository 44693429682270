import React from 'react';
import PropertiesGrid from '.';

type PropertyGridComponentProps = {
  totalPropertiesCount: number;
  properties: any[];
  columns: number;
  page: number;
  pageSize: number;
  noPropertiesFoundMessage: string;
};

const LandingPagePropertyGrid = ({
  totalPropertiesCount,
  properties,
  columns,
  page,
  pageSize,
  noPropertiesFoundMessage,
}: PropertyGridComponentProps) => {
  if (!totalPropertiesCount) {
    return (
      <div className="text-center py-6 my-16">
        <span className="text-gray-500">{noPropertiesFoundMessage}</span>
      </div>
    );
  }

  return (
    <div className="mt-14 mb-14 max-w-[1172px] relative mx-auto px-4">
      <PropertiesGrid properties={properties} columns={columns} page={page} pageSize={pageSize} totalPropertiesCount={totalPropertiesCount} />
    </div>
  );
};

export default LandingPagePropertyGrid;
