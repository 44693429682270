import { faSearch } from '@fortawesome/pro-solid-svg-icons';
import Link from 'next/link';
import { useRouter } from 'next/router';
import styled from 'styled-components';
import { Button } from 'legacy-ui';
import { Heading } from 'legacy-ui';
import Container from '../../sections/common/Container';
import { GetStartedCMSResult } from '../../types/cms/generalTypes';
import { LanguageKey } from '../../types/common';
import { getSearchLink } from '../../utils/links';

const StyledGetStarted = styled.section`
  background-color: ${(props) => props.theme.color.green80};
  color: white;
  text-align: center;
  padding: 96px 0;
  @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
    padding: 144px 0;
  }
  h3 {
    margin-bottom: 32px;
    @media only screen and (min-width: ${(props) => props.theme.breakpoint.md}) {
      margin-bottom: 48px;
    }
  }
`;

const GetStarted = ({ fromCMS }: { fromCMS?: GetStartedCMSResult }) => {
  const { locale } = useRouter();
  return (
    <StyledGetStarted>
      <Container>
        <Heading size="r" tag="h3">
          {fromCMS?.Headline}
        </Heading>
        {fromCMS?.CTA && (
          <Link href={getSearchLink(locale as LanguageKey)} passHref>
            <a>
              <Button color="primary" leftIcon={faSearch}>
                {fromCMS?.CTA.Label}
              </Button>
            </a>
          </Link>
        )}
      </Container>
    </StyledGetStarted>
  );
};

export default GetStarted;
